<script>
    export let showTVLText= true;

	const isProd = process.env.NODE_ENV.startsWith('prod');
	const subvisChartsLink = isProd ? 'https://analytics.subvis.io/' : 'https://analytics.subvis.info/';
</script>
<style>
.tvl-button{
 background:#1D3FAA;
}
@media (min-width: 600px) {
    .staking-icon{
        margin-right:4px;
    }
  }
</style>
<a href={subvisChartsLink} target="_blank" class="cursor-pointer">
  <button class="btn  mr-4 font-medium tvl-button text-white focus:outline-none">
    {#if showTVLText}
       <p class="staking-icon">Staking TVL</p>
    {/if}
    <i>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <rect width="24" height="24" fill="#1D3FAA"/>
            <path d="M6.4 9.2H6.6C7.37 9.2 8 9.83 8 10.6V17.6C8 18.37 7.37 19 6.6 19H6.4C5.63 19 5 18.37 5 17.6V10.6C5 9.83 5.63 9.2 6.4 9.2ZM12 5C12.77 5 13.4 5.63 13.4 6.4V17.6C13.4 18.37 12.77 19 12 19C11.23 19 10.6 18.37 10.6 17.6V6.4C10.6 5.63 11.23 5 12 5ZM17.6 13C18.37 13 19 13.63 19 14.4V17.6C19 18.37 18.37 19 17.6 19C16.83 19 16.2 18.37 16.2 17.6V14.4C16.2 13.63 16.83 13 17.6 13Z" fill="white"/>
        </svg>
    </i>
  </button>
</a>
