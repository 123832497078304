<script lang="ts">
  import { Link, useLocation } from 'svelte-navigator';
  import EmailSubscriptionPanel from './EmailSubscriptionPanel.svelte';
  import MediaQuery from './MediaQuery.svelte';
  import NetworkToggleBtn from './NetworkToggle.svelte';

  const location = useLocation();
  $: path = $location.pathname;
</script>

<nav class="side-nav">
	<div class="flex  sm:flex-col xl:flex-row">
		<Link to="/" class="h-9 xl:mr-auto flex justify-center">
			<img alt="Subvisio" src="/logo.svg" />
		</Link>
		<div class= "flex border border-white  rounded xl:mt-0 sm:mt-6 sm:w-16 xl:w-20 h-9">
			<NetworkToggleBtn/>
		 </div>
	</div>

  <div class="side-nav__devider my-6"></div>
  <ul>
    <li>
      <Link to="/" class="side-menu {path === '/' ? 'side-menu--active' : ''}">
        <div class="side-menu__icon"><span class="material-icons md-18">gavel</span></div>
        <div class="side-menu__title">Auction</div>
      </Link>
    </li>
    <li>
      <Link to="/crowdloan" class="side-menu {path.startsWith('/crowdloan') ? 'side-menu--active' : ''}">
        <div class="side-menu__icon"><span class="material-icons md-18">payments</span></div>
        <div class="side-menu__title">Crowdloan</div>
      </Link>
    </li>
    <li class="side-nav__devider my-6"></li>
    <li>
      <Link to="/staking" class="side-menu {path.startsWith('/staking') ? 'side-menu--active' : ''}">
        <div class="side-menu__icon"><span class="material-icons md-18">toll</span></div>
        <div class="side-menu__title">Staking</div>
      </Link>
    </li>
  </ul>
  <div class="side-nav__devider my-6"></div>
  <ul>
    <li>
      <a href="https://twitter.com/subvisioapp" target="_blank" class="side-menu">
        <div class="side-menu__icon">
          <svg
            height="24px"
            viewBox="-21 -81 681.33464 681"
            width="24px"
            xmlns="http://www.w3.org/2000/svg"
            fill="#ffffff"
            ><path
              d="m200.964844 515.292969c241.050781 0 372.871094-199.703125 372.871094-372.871094 0-5.671875-.117188-11.320313-.371094-16.9375 25.585937-18.5 47.824218-41.585937 65.371094-67.863281-23.480469 10.441406-48.753907 17.460937-75.257813 20.636718 27.054687-16.230468 47.828125-41.894531 57.625-72.488281-25.320313 15.011719-53.363281 25.917969-83.214844 31.808594-23.914062-25.472656-57.964843-41.402344-95.664062-41.402344-72.367188 0-131.058594 58.6875-131.058594 131.03125 0 10.289063 1.152344 20.289063 3.398437 29.882813-108.917968-5.480469-205.503906-57.625-270.132812-136.921875-11.25 19.363281-17.742188 41.863281-17.742188 65.871093 0 45.460938 23.136719 85.605469 58.316407 109.082032-21.5-.660156-41.695313-6.5625-59.351563-16.386719-.019531.550781-.019531 1.085937-.019531 1.671875 0 63.46875 45.171875 116.460938 105.144531 128.46875-11.015625 2.996094-22.605468 4.609375-34.558594 4.609375-8.429687 0-16.648437-.828125-24.632812-2.363281 16.683594 52.070312 65.066406 89.960937 122.425781 91.023437-44.855469 35.15625-101.359375 56.097657-162.769531 56.097657-10.5625 0-21.003906-.605469-31.2617188-1.816407 57.9999998 37.175781 126.8710938 58.871094 200.8867188 58.871094"
            ></path></svg
          >
        </div>
        <div class="side-menu__title">Twitter</div>
      </a>
    </li>
    <li>
      <a href="https://discord.com/invite/HZcNxEZFy9" target="_blank" class="side-menu">
        <div class="side-menu__icon">
          <svg width="24" height="27" viewBox="0 0 24 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M9.46233 11.2762C8.68062 11.2762 8.06348 11.9482 8.06348 12.7681C8.06348 13.5879 8.69433 14.2599 9.46233 14.2599C10.244 14.2599 10.8612 13.5879 10.8612 12.7681C10.8749 11.9482 10.244 11.2762 9.46233 11.2762ZM14.468 11.2762C13.6863 11.2762 13.0692 11.9482 13.0692 12.7681C13.0692 13.5879 13.7 14.2599 14.468 14.2599C15.2498 14.2599 15.8669 13.5879 15.8669 12.7681C15.8669 11.9482 15.2498 11.2762 14.468 11.2762Z"
              fill="white"></path>
            <path
              d="M21.1886 0H2.81143C1.26171 0 0 1.23648 0 2.76864V20.9395C0 22.4717 1.26171 23.7082 2.81143 23.7082H18.3634L17.6366 21.2218L19.392 22.8211L21.0514 24.3264L24 26.88V2.76864C24 1.23648 22.7383 0 21.1886 0ZM15.8949 17.5526C15.8949 17.5526 15.4011 16.9747 14.9897 16.464C16.7863 15.9667 17.472 14.8646 17.472 14.8646C16.9097 15.2275 16.3749 15.4829 15.8949 15.6576C15.2091 15.9398 14.5509 16.128 13.9063 16.2355C12.5897 16.4774 11.3829 16.4102 10.3543 16.2221C9.57257 16.0742 8.90057 15.8592 8.33829 15.6442C8.02286 15.5232 7.68 15.3754 7.33714 15.1872C7.296 15.1603 7.25486 15.1469 7.21371 15.12C7.18629 15.1066 7.17257 15.0931 7.15886 15.0797C6.912 14.9453 6.77486 14.8512 6.77486 14.8512C6.77486 14.8512 7.43314 15.9264 9.17486 16.4371C8.76343 16.9478 8.256 17.5526 8.256 17.5526C5.22514 17.4586 4.07314 15.5098 4.07314 15.5098C4.07314 11.1821 6.048 7.67424 6.048 7.67424C8.02286 6.22272 9.90171 6.26304 9.90171 6.26304L10.0389 6.42432C7.57029 7.1232 6.432 8.18496 6.432 8.18496C6.432 8.18496 6.73371 8.02368 7.24114 7.7952C8.70857 7.16352 9.87429 6.9888 10.3543 6.94848C10.4366 6.93504 10.5051 6.9216 10.5874 6.9216C11.424 6.81408 12.3703 6.7872 13.3577 6.89472C14.6606 7.04256 16.0594 7.41888 17.4857 8.18496C17.4857 8.18496 16.4023 7.17696 14.0709 6.47808L14.2629 6.26304C14.2629 6.26304 16.1417 6.22272 18.1166 7.67424C18.1166 7.67424 20.0914 11.1821 20.0914 15.5098C20.0914 15.5098 18.9257 17.4586 15.8949 17.5526V17.5526Z"
              fill="white"></path>
          </svg>
        </div>
        <div class="side-menu__title">Discord</div>
      </a>
    </li>
    <li>
      <a href="mailto:support@subvis.io" target="_blank" class="side-menu">
        <div class="side-menu__icon">
          <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#ffffff"
            ><path d="M0 0h24v24H0z" fill="none"></path><path
              d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z"
            ></path></svg
          >
        </div>
        <div class="side-menu__title">Support</div>
      </a>
    </li>
  </ul>
</nav>
<MediaQuery query="(min-width: 1280px)" let:matches="{isBigScreen}">
  {#if isBigScreen}
    <EmailSubscriptionPanel isCompact="{true}" />
  {/if}
</MediaQuery>
