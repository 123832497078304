import { __rest } from "tslib";
import { times } from 'lodash-es';
export const normalize = (node) => {
    if (Array.isArray(node)) {
        return node.map(normalize);
    }
    if (typeof node === 'object') {
        return Object.entries(node).reduce((prev, [key, val]) => {
            if (typeof val == 'object' && Array.isArray(val === null || val === void 0 ? void 0 : val['nodes'])) {
                return Object.assign(Object.assign({}, prev), { [key]: normalize(val['nodes']), [key + 'PageInfo']: val['pageInfo'], [key + 'Total']: val['totalCount'] });
            }
            if (Array.isArray(val)) {
                return Object.assign(Object.assign({}, prev), { [key]: normalize(val) });
            }
            if (key === '__typename') {
                return prev;
            }
            return Object.assign(Object.assign({}, prev), { [key]: val });
        }, {});
    }
    return node;
};
export const getSlotsCombination = (first, last) => {
    const length = last - first + 1;
    return times(length, (idx) => times(length - idx, (n) => ({ start: first + idx, end: last - n }))).flat();
};
const getTimeUnitInWord = ({ value, name }) => value > 0 ? `${value} ${value != 1 ? name + 's' : name}` : '';
export const getTimeDiffInWord = (timeDeltaMs) => {
    const timeUnits = [
        { value: 86400000, name: 'day' },
        { value: 3600000, name: 'hour' },
        { value: 60000, name: 'min' },
        { value: 1000, name: 'sec' }
    ];
    const { units } = timeUnits.reduce(({ remain, units }, unit) => {
        const { value, name } = unit;
        const curUnitValue = Math.floor(remain / value);
        return { remain: remain % value, units: units.concat({ name, value: curUnitValue }) };
    }, { remain: timeDeltaMs, units: [] });
    const result = units.map(getTimeUnitInWord).join(' ');
    return result;
};
export const getDateFromBlockNum = (blockNum, curBlockNum, timestamp, displayTime) => {
    const diff = blockNum - curBlockNum;
    const timeDiff = diff * 6000;
    const date = timestamp ? new Date(timestamp) : new Date();
    const formatter = displayTime ? dateTimeFormatter : dateFormatter;
    return formatter.format(new Date(date.getTime() + timeDiff)).replace(',', ' ');
};
export const dateFormatter = new Intl.DateTimeFormat('en-US');
export const dateTimeFormatter = new Intl.DateTimeFormat('en-AU', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour12: false,
    hour: 'numeric',
    minute: '2-digit',
    second: '2-digit'
});
export const getColSpan = (allSlots, curSlots) => {
    const { result: occupied } = allSlots.reduce(({ result, idx }, cur) => {
        if (cur === curSlots[idx]) {
            return {
                result: result.concat(1),
                idx: idx + 1
            };
        }
        return {
            result: result.concat(0),
            idx
        };
    }, { result: [], idx: 0 });
    const span = occupied.filter((val) => !!val).reduce((all, cur) => all + cur, 0);
    const startIdx = occupied.findIndex((val) => !!val);
    const result = occupied.slice(0, startIdx).concat(span, times(allSlots.length - startIdx - span, () => 0));
    return result;
};
const findContinuation = (cur, segments, prev = []) => {
    const next = cur.lastLease + 1;
    return segments.reduce((current, _a, idx) => {
        var { firstLease, lastLease } = _a, others = __rest(_a, ["firstLease", "lastLease"]);
        if (next === firstLease) {
            const curBranch = prev.concat(Object.assign({ firstLease, lastLease }, others));
            current.push(curBranch);
            const rest = [...segments.slice(0, idx), ...segments.slice(idx + 1)];
            if (rest.length) {
                current.push(...findContinuation({ firstLease, lastLease }, rest, curBranch));
            }
        }
        return current;
    }, []);
};
export const gatherCombination = (sortedLease) => {
    return sortedLease.reduce((all, lease) => {
        const result = findContinuation(lease, sortedLease, [lease]);
        const group = [...result, [lease]].map((series) => ({
            starts: series[0].firstLease,
            ends: series[series.length - 1].lastLease,
            totalBidAmount: series.reduce((total, { latestBidAmount }) => {
                return total + (latestBidAmount ? parseInt(latestBidAmount, 10) : 0);
            }, 0),
            totalLockupValue: series.reduce((total, { latestBidAmount, firstLease, lastLease }) => {
                return total + (lastLease - firstLease + 1) * (latestBidAmount ? parseInt(latestBidAmount, 10) : 0);
            }, 0),
            winningChance: series.reduce((total, { leadingRate }) => total * (leadingRate ? parseFloat(leadingRate) : 0), 1),
            series
        }));
        return all.concat(group);
    }, []);
};
export function validateEmail(email) {
    return /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/.test(email);
}
