<script>
	const { STAGE } = process.env;
	let isPolkadotNetwork =  STAGE === 'polkadot';
</script>


 <button  class="p-1.5 sm:w-8 xl:w-10 {isPolkadotNetwork? 'bg-white' : 'bg-primary'}" type="button" disabled={isPolkadotNetwork}>
	<a href="https://polkadot.subvis.io/" target="_blank" class={isPolkadotNetwork? "disabledA" : ""}>
	<svg xmlns="http://www.w3.org/2000/svg"  height="100%" width="100%" viewBox="0 0 24 24" fill="none">
	  <path xmlns="http://www.w3.org/2000/svg" d="M12.3301 1C7.73723 1.01846 4.01845 4.73908 4 9.33427C4 10.2545 4.14847 11.1686 4.43926 12.0415C4.65626 12.6356 5.30021 12.9556 5.90463 12.7719C6.48885 12.5477 6.79897 11.9087 6.61272 11.311C6.36586 10.6307 6.25604 9.9091 6.28855 9.1866C6.3887 5.86766 9.15868 3.25804 12.476 3.35824C15.7933 3.45844 18.4016 6.22979 18.3015 9.54873C18.2074 12.6558 15.7616 15.1793 12.6596 15.3683C12.6596 15.3683 11.4903 15.4395 10.9087 15.5107C10.6943 15.5415 10.4817 15.5836 10.2718 15.6373C10.2419 15.668 10.1936 15.668 10.1628 15.6381L10.1619 15.6373C10.1409 15.61 10.1409 15.5713 10.1619 15.5441L10.3429 14.5561L11.4411 9.61465C11.5729 8.99763 11.1802 8.39027 10.5634 8.25842C9.94671 8.12658 9.33965 8.51947 9.20787 9.1365C9.20787 9.1365 6.59603 21.2318 6.59603 21.3417C6.44668 21.9297 6.8016 22.5274 7.38934 22.6768C7.39549 22.6786 7.40251 22.6803 7.40866 22.6812H7.46928C8.05613 22.8333 8.65528 22.4799 8.80639 21.8928C8.80903 21.8831 8.81166 21.8735 8.81342 21.8629C8.81166 21.8445 8.81166 21.826 8.81342 21.8084C8.84592 21.666 9.17537 20.0567 9.17537 20.0567C9.42487 18.8419 10.4035 17.9111 11.6282 17.723C11.8803 17.6844 12.9398 17.6132 12.9398 17.6132C17.5152 17.157 20.8544 13.076 20.3976 8.49838C19.9812 4.32333 16.5242 1.10899 12.3301 1Z"fill="{isPolkadotNetwork? '#1D3FAA': 'white'}" fill-opacity={isPolkadotNetwork ? '':"0.6"}/>
	  <path xmlns="http://www.w3.org/2000/svg" d="M12.8353 20.2166C12.0842 20.0602 11.3497 20.5427 11.1934 21.2934C11.1916 21.3004 11.1907 21.3074 11.189 21.3145C11.0264 22.0607 11.4991 22.7981 12.2458 22.9607C12.2467 22.9607 12.2476 22.9607 12.2485 22.9616H12.2871C13.0181 23.1383 13.7534 22.6883 13.9291 21.9579C13.9308 21.9526 13.9317 21.9464 13.9335 21.9412V21.8647C14.0626 21.1105 13.5812 20.3872 12.8353 20.2166Z" fill="{isPolkadotNetwork? '#1D3FAA': 'white'}" fill-opacity={isPolkadotNetwork ? '':"0.6"}/>
	</svg>
    </a>
  </button>


 <button  class="p-1.5 sm:w-8 xl:w-10 {isPolkadotNetwork? 'bg-primary': 'bg-white'}" type="button" disabled={!isPolkadotNetwork}>
	<a href="https://kusama.subvis.io/" target="_blank" class={!isPolkadotNetwork? "disabledA" : ""}>
	<svg xmlns="http://www.w3.org/2000/svg" height="100%" width="100%" viewBox="0 0 24 24" fill="none">
	  <path d="M22.5972 5.7833C22.2361 5.49858 21.8056 5.10969 21.0208 5.01247C20.2847 4.91525 19.5347 5.4083 19.0278 5.73469C18.5208 6.06108 17.5625 7.01941 17.1667 7.31108C16.7708 7.60275 15.7569 7.87358 14.125 8.85275C12.4931 9.83192 6.09028 13.943 6.09028 13.943L7.75694 13.9639L0.326389 17.7903H1.06944L0 18.6028C0 18.6028 0.944444 18.8528 1.73611 18.3528V18.5819C1.73611 18.5819 10.5833 15.0958 12.2917 15.9986L11.25 16.3041C11.3403 16.3041 13.0208 16.4153 13.0208 16.4153C13.0616 16.7633 13.1786 17.098 13.3634 17.3957C13.5483 17.6933 13.7964 17.9466 14.0903 18.1375C15.1042 18.8041 15.125 19.1722 15.125 19.1722C15.125 19.1722 14.5972 19.3875 14.5972 19.6583C14.5972 19.6583 15.375 19.4222 16.0972 19.443C16.5556 19.4604 17.0102 19.5327 17.4514 19.6583C17.4514 19.6583 17.3958 19.3666 16.6944 19.1722C15.9931 18.9778 15.2986 18.2139 14.9583 17.7972C14.7499 17.532 14.6141 17.2171 14.5641 16.8835C14.5142 16.5499 14.5519 16.209 14.6736 15.8944C14.9167 15.2625 15.7639 14.9153 17.5139 14.0125C19.5764 12.943 20.0486 12.1514 20.3403 11.5333C20.6319 10.9152 21.0625 9.68608 21.3056 9.10969C21.6111 8.36664 21.9861 7.9708 22.2986 7.73469C22.6111 7.49858 24 6.97775 24 6.97775C24 6.97775 22.9375 6.05414 22.5972 5.7833Z" fill="{isPolkadotNetwork? 'white': '#1D3FAA'}" fill-opacity={isPolkadotNetwork ? '0.6':""}/>
	</svg>
    </a>
</button>

<style>
  .disabledA{
	pointer-events: none;
    cursor: default;
   }
</style>
